<template>
  <div>
    <h2>プロフィールの編集</h2>
    <div class="">
      テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
    </div>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body mt-1 mb-2">
        テキストテキストテキストテキストテキストテキストテキストテキスト
      </div>
    </b-alert>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <b-row>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
            >
              キャンセル
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>

      </b-form>
      <!-- <SuccessBasic
        ref="sbchild"
        to-page="student-profile"
      /> -->
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import updateData from '@/firestore/data/update'
/* eslint-disable */
import menulist from '@/components/conf/JPCAREER様_学習PF_受講生_プロフィール_20210921.json'
import InputParts from '@/components/molecules/input/InputParts.vue'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
/* eslint-enable */

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      choice,
      selected: [],
      useValueDicList: ['checkbox', 'selectSingle'],
    }
  },
  computed: {
    ...mapGetters('profileBasic', ['profileDic']),
  },
  async mounted() {
    this.inputForm = this.profileDic
    window.console.log('🎉', this.inputForm.learningCategory)
  },
  methods: {
    async confirm() {
      // window.console.log('🐶', this.inputForm)
      this.status = 2
      const data = {
        collection: 'user',
        d: this.inputForm,
      }
      const response = await updateData(data)
      if (response.status === 'success') {
        this.success()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'student-profile' })
        this.status = 1
      })
    },
    cancel() {
      this.$router.push({ name: 'student-profile' })
    },
  },
}
</script>

<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditProfile />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditProfile from '@/components/student/profile/EditProfile.vue'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    EditProfile,
    Sidebar,
  },
}
</script>
